import LayoutA4 from "../../components/layout-a4"
import React from "react"
import  Login from '../../components/login'

export default class A4Login extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA4/profile/enrollInfoList"
    return(
      <LayoutA4 >
        <Login color={color} src={src} />
      </LayoutA4>
    )
  }
}